import affiliatesFragment from './Search/affiliates';
import facetsLimitedFragment from './Search/limited';
import { mediaFragment } from './Search/mediaFragment';

const fragment = () => {
	return `
    fragment ProfileProperties on Storefront {
      id
      vendorId
      accountId
      accountStatus
      name
      displayId
      isTestData
      ivolScoreId
      adTier
      claimedStatus
      marketCode
      movedProfileId
      purchaseStatus
      vendorTier
      dek {
        description
      }
      addOns {
        code
      }
      affiliates {
        id
        name
        description
        singular {
          slug
          term
        }
        plural {
          slug
          term
        }
        isSelected
        ...AffiliatesRecurse
      }
      ai {
        photo {
          height
          id
          mediaType
          url
          width
        }
      }
      categories {
        id
        code
        name
        displayName
      }
      categoryInfo {
        plural {
          slug
          term
        }
      }
      facets {
        id
        name
        ...FacetsRecurseLimited
      }
      location {
        address {
          address1
          address2
          city
          state
          postalCode
          latitude
          longitude
          isPublic
        }
        serviceArea
      }
      logo {
        url
      }
      mediaSummary {
        total
        media {
          ...MediaItems
        }
      }
      reviewSummary {
        count
        overallRating
      }
      salesProfiles {
        vendorTier
        marketCode
        addOns {
          code
          name
        }
      }
      settings {
        AIPhoto
      }
      siteUrls {
        siteId
        siteName
        uri
        relativeUri
      }
      statistics {
        completionScore
        ivolScore
      }
      storefrontCard {
        id
        mediaType
        url
      }
      vendorBehavior {
        quickResponder
      }
      complete
  }
  ${affiliatesFragment}
  ${facetsLimitedFragment}
  ${mediaFragment}
  `;
};

export const personalizedSortFragment = `
    fragment PersonalizedSortProfileProperties on Storefront {
      id
      vendorId
      accountId
      accountStatus
      name
      displayId
      isTestData
      adTier
      claimedStatus
      marketCode
      movedProfileId
      personalizedScoreId
      purchaseStatus
      vendorTier
      dek {
        description
      }
      addOns {
        code
      }
      affiliates {
        id
        name
        description
        singular {
          slug
          term
        }
        plural {
          slug
          term
        }
        isSelected
        ...AffiliatesRecurse
      }
      ai {
        photo {
          height
          id
          mediaType
          url
          width
        }
      }
      categories {
        id
        code
        name
        displayName
      }
      categoryInfo {
        plural {
          slug
          term
        }
      }
      facets {
        id
        name
        ...FacetsRecurseLimited
      }
      faq {
        pricing {
          videographyAvailable
        }
      }
      location {
        address {
          address1
          address2
          city
          state
          postalCode
          latitude
          longitude
          isPublic
        }
        serviceArea
      }
      logo {
        url
      }
      mediaSummary {
        total
        media {
          ...MediaItems
        }
      }
      pricing {
        cost {
          gte
          lte
        }
      }
      reviewSummary {
        count
        overallRating
      }
      salesProfiles {
        vendorTier
        marketCode
        addOns {
          code
          name
        }
      }
      settings {
        AIPhoto
      }
      siteUrls {
        siteId
        siteName
        uri
        relativeUri
      }
      statistics {
        completionScore
        personalizedScore
      }
      storefrontCard {
        id
        mediaType
        url
      }
      vendorBehavior {
        quickResponder
      }
      complete
  }
  ${affiliatesFragment}
  ${facetsLimitedFragment}
  ${mediaFragment}
  `;

export default fragment;
